import React, { useState } from "react";
import {
  Collapse,
  Container,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
} from "reactstrap";

import ScrollspyNav from "./scrollSpy";

import StickyHeader from "react-sticky-header";
import "react-sticky-header/styles.css";

const NavbarPage = ({ navItems = [], navClass = "" }) => {
  const [isOpenMenu, setOpenMenu] = useState(false);

  const toggle = () => {
    setOpenMenu((prevState) => !prevState);
  };

  // Store all Navigation bar id into TargetID variable (Used for Scrollspy)
  const targetIds = navItems.map((item) => {
    return item.anchor;
  });

  return (
    <React.Fragment>
      <StickyHeader
        header={
          <Navbar
            expand="lg"
            fixed="top"
            className={"navbar-custom sticky sticky-dark " + navClass}
          >
            <Container>
              <NavbarBrand className="logo text-uppercase" href="/">
                DEVDEN
              </NavbarBrand>
              <NavbarToggler onClick={toggle}>
                <i className="mdi mdi-menu"></i>
              </NavbarToggler>

              <Collapse id="navbarCollapse" isOpen={isOpenMenu} navbar>
                <ScrollspyNav
                  scrollTargetIds={targetIds}
                  scrollDuration={800}
                  headerBackground={true}
                  activeNavClass="active"
                  className="navbar-collapse"
                >
                  <Nav navbar className="navbar-center" id="mySidenav">
                    {navItems.map((item) => (
                      <NavItem
                        key={String(item.id)}
                        className={item.heading === "Home" ? "active" : ""}
                      >
                        <NavLink href={"#" + item.anchor}>
                          {" "}
                          {item.heading}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                  <div className="nav-button ms-auto">
                    <Nav navbar className="navbar-end">
                      {/* <li>
                          <Button
                            color="none"
                            type="button"
                            className="btn btn-primary navbar-btn btn-rounded waves-effect waves-light"
                          >
                            Try it Free
                          </Button>
                        </li> */}
                    </Nav>
                  </div>
                </ScrollspyNav>
              </Collapse>
            </Container>
          </Navbar>
        }
        stickyOffset={-100}
      ></StickyHeader>
    </React.Fragment>
  );
};

export default NavbarPage;
