import React from "react";
import { Container } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";

const Blog = () => {
  return (
    <section className="section bg-light pt-5" id="blog">
      <Container>
        {/* section title */}
        <SectionTitle
          title="BLOG"
          desc="We are working to bring something unique with our blog. Wish us luck and help us by giving suggestions."
        />

        <div className="d-flex justify-content-center">
          <img
            src={"assets/images/coming-soon.png"}
            alt={"Coming Soon"}
            className={"img-fluid"}
            width={350}
            height={350}
          />
        </div>

        {/* <Row className="mt-4">
          <BlogBox blogs={blogs} />
        </Row> */}
      </Container>
    </section>
  );
};

export default Blog;
