import React from "react";

import Blog from "../../components/Blog/Blog";
import Footer from "../../components/Footer/footer";
import NavbarPage from "../../components/Navbar/Navbar_Page";
import Pricing from "../../components/Pricing/pricing";
import Process from "../../components/Process/Process";
import Projects from "../../components/Projects";
import Service from "../../components/Service/service";
import Social from "../../components/Social/Social";
import Testimonials from "../../components/Testimonials/Testimonials";
import WebsiteDesc from "../../components/WebsiteDesc/WebsiteDesc";
import menu from "../../constants/data/menu.json";
import Section from "./section";

const Home = () => {
  return (
    <React.Fragment>
      {/* Importing Navbar */}
      <NavbarPage navItems={menu} navClass={""} />

      {/* section */}
      <Section />

      {/* services */}
      <Service sectionClass="pt-5" />

      {/* about us */}
      <Projects />

      {/* website description */}
      <WebsiteDesc />

      {/* pricing */}
      <Pricing />

      {/* team */}
      {/* <Team /> */}

      {/* process */}
      <Process />

      {/* testimonial */}
      <Testimonials />

      {/* get started */}
      {/* <GetStart /> */}

      {/* blog */}
      <Blog />

      {/* contact */}
      {/* <Contact /> */}

      {/* social */}
      <Social />

      {/* footer */}
      <Footer />
    </React.Fragment>
  );
};

export default Home;
