import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import processes from "../../constants/data/processes.json";

import SectionTitle from "../common/section-title";
import ProcessBox from "./ProcessBox";

const Process = () => {
  return (
    <React.Fragment>
      <section className="section bg-light">
        <Container>
          {/* section title */}
          <SectionTitle
            title="How we Operate"
            desc="Our process is measured in 3 simple steps which allows us to achieve the outcome expected"
          />

          <Row>
            <Col lg={6} className="text-center process-left-icon-1">
              <i className="pe-7s-angle-right"></i>
            </Col>
            <Col lg={6} className="text-center process-left-icon-2">
              <i className="pe-7s-angle-right"></i>
            </Col>
          </Row>
          <Row className="mt-5">
            <ProcessBox processes={processes} />
            {/* <div className="text-center mx-auto">
              <Link
                to="#"
                className="btn btn-primary waves-light waves-effect mt-5"
              >
                Get Started <i className="mdi mdi-arrow-right"></i>
              </Link>
            </div> */}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Process;
