import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

// Importing Modal
import ModalSection from "../../components/common/ModalSection";

const Section = () => {
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  return (
    <React.Fragment>
      <section
        className="section bg-home home-half"
        id="home"
        data-image-src="images/bg-home.jpg"
      >
        <div className="bg-overlay"></div>
        <div className="display-table">
          <div className="display-table-cell">
            <Container>
              <Row>
                <Col
                  lg={{ size: 8, offset: 2 }}
                  className="col-lg-8 offset-lg-2 text-white text-center"
                >
                  <h1 className="home-title">
                    Helping the world transition into the world of technology
                  </h1>
                  <p className="pt-3 home-desc">
                    Watch how <strong>DevDen</strong> is moving forward in this
                    progressive world.
                  </p>
                  <p className="play-shadow mt-4">
                    <Link
                      to="#"
                      onClick={handleOpenModal}
                      className="play-btn video-play-icon"
                    >
                      <i className="mdi mdi-play text-center"></i>
                    </Link>
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div className="wave-effect wave-anim">
          <div className="waves-shape shape-one">
            <div
              className="wave wave-one"
              style={{
                backgroundImage: `url(assets/images/wave-shape/wave1.png)`,
              }}
            ></div>
          </div>
          <div className="waves-shape shape-two">
            <div
              className="wave wave-two"
              style={{
                backgroundImage: `url(assets/images/wave-shape/wave2.png)`,
              }}
            ></div>
          </div>
          <div className="waves-shape shape-three">
            <div
              className="wave wave-three"
              style={{
                backgroundImage: `url(assets/images/wave-shape/wave3.png)`,
              }}
            ></div>
          </div>
        </div>

        {/* Render ModalSection Component for Modal */}
        <ModalSection
          channel="youtube"
          isOpen={openModal}
          onClose={() => setOpenModal(false)}
          videoId={"seS03o0oOkk"}
        />
      </section>
    </React.Fragment>
  );
};

export default Section;
