import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

const WebsiteDesc = () => {
  return (
    <React.Fragment>
      <section className="section section-lg bg-web-desc">
        <div className="bg-overlay"></div>
        <Container>
          <Row>
            <Col lg={12} className="text-center">
              <h2 className="text-white">
                Build your dream into a digital product today
              </h2>
              <p className="pt-3 home-desc">
                We believe in providing the right product. We ensure quality,
                scalability, connection, and communication with our clients so
                we never fail as a business and our clients as customers.
              </p>
              {/* <Link
                to="#pricing"
                className="btn btn-white mt-4 waves-effect waves-light mb-5"
              >
                View Our Process
              </Link> */}
            </Col>
          </Row>
        </Container>
        <div className="bg-pattern-effect">
          <img src="assets/images/bg-pattern.png" alt="dorsin" />
        </div>
      </section>
    </React.Fragment>
  );
};

export default WebsiteDesc;
