import React from "react";
import { Col, Container, Row } from "reactstrap";
import social from "../../constants/data/social.json";

const Social = () => {
  return (
    <React.Fragment>
      <section className="contact-social bg-light">
        <Container>
          <Row className="align-items-center">
            <Col lg={6}>
              <ul className="list-inline social mt-4">
                {social.map((item) => {
                  return (
                    <SocialIcon icon={item.icon} key={item.id} to={item.url} />
                  );
                })}
              </ul>
            </Col>
            <Col lg={3} className="mt-4">
              <p className="contact-title">
                <i className="pe-7s-call"></i> &nbsp;+92 349 527 28 06
              </p>
            </Col>
            <Col lg={3} className="mt-4 text-right">
              <p className="contact-title">
                <i className="pe-7s-mail-open"></i>&nbsp; support@devden.org
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

const SocialIcon = ({ icon, to }) => {
  return (
    <li className="list-inline-item">
      <a target={"_blank"} rel={"noreferrer"} href={to} className="social-icon">
        <i className={`mdi ${icon}`}></i>
      </a>
    </li>
  );
};

export default Social;
