import React from "react";
import { Link } from "react-router-dom";
import { Col } from "reactstrap";

const PricingBox = ({ prices }) => {
  return (
    <React.Fragment>
      {prices.map((pricing, key) => (
        <Col lg={4} key={key}>
          <div
            className={
              pricing.isActive
                ? "text-center pricing-box bg-white hover-effect price-active"
                : "text-center pricing-box hover-effect"
            }
          >
            <h4 className="text-uppercase">{pricing.title}</h4>
            <h1>${pricing.price}</h1>
            <h6 className="text-uppercase text-muted">
              Billing Per {pricing.duration}
            </h6>
            <div className="pricing-border"></div>
            <div className="plan-features mt-4">
              {pricing.features.map((feature, key) => (
                <p
                  className={
                    key === pricing.features.length - 1 ? "text-muted" : ""
                  }
                  key={String(key)}
                >
                  {feature}
                </p>
              ))}
            </div>
            {/* <Link
              to="#"
              className="btn btn-primary waves-effect waves-light mt-4"
            >
              Explore More
            </Link> */}
          </div>
        </Col>
      ))}
    </React.Fragment>
  );
};

export default PricingBox;
