import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";
import ServiceBox from "./services-box";

class Process extends Component {
  constructor(props) {
    super(props);
    this.state = {
      services1: [
        {
          icon: "pe-7s-leaf",
          title: "Digitalization",
          desc: "Transitioning businesses to adapt latest technologies in a cost reliable, efficient and robust way.",
        },
        {
          icon: "pe-7s-plugin",
          title: "Artificial Intelligence",
          desc: "Building AI Powered Applications for consumers/enterprises to compete real world results into progress and advancement",
        },
        {
          icon: "pe-7s-phone",
          title: "Mobile Applications",
          desc: "Developing Mobile Applications to provide more value, a stronger brand and reach higher customer engagement level.",
        },
      ],
      services2: [
        {
          icon: "pe-7s-global",
          title: "Web Development",
          desc: "Developing high end web applications cost effectively for minor to major products and providing tools for accessibility.",
        },
        {
          icon: "pe-7s-gleam",
          title: "Blockchain Applications",
          desc: "Building Blockchain applications to provide the sense of security, innovation, and trust among businesses.",
        },
        {
          icon: "pe-7s-joy",
          title: "Game Development",
          desc: "Building Video Games using Unity, Unreal Engine and more tools to provide the world with something to relax and have fun with.",
        },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className={"section " + this.props.sectionClass} id="services">
          <Container>
            {/* section title */}
            <SectionTitle
              title="Services"
              desc="We craft digital, graphic and dimensional thinking, to create category leading brand experiences that have meaning and add a value for our clients."
            />

            <Row className="mt-4">
              {/* services box */}
              <ServiceBox services={this.state.services1} />
            </Row>

            <Row className="mt-4">
              {/* service box */}
              <ServiceBox services={this.state.services2} />
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Process;
