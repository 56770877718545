import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import SectionTitle from "../common/section-title";
import products from "../../constants/data/products.json";

const Projects = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const onNext = () => {
    if (currentIndex === products.length) {
      return;
    }
    setCurrentIndex((prevState) => prevState + 1);
  };

  const onPrev = () => {
    if (currentIndex === 0) {
      return;
    }
    setCurrentIndex((prevState) => prevState - 1);
  };

  return (
    <section className="section bg-light" id="products">
      <Container>
        <SectionTitle
          title="Our Products"
          desc="Some of our products we are proud of. Every product has it's own journey. Scroll through and learn more about our products."
        />
        <br />
        <br />
        <div
          id="carouselExampleControls"
          className="carousel slide"
          data-ride="carousel"
        >
          <div className="carousel-inner">
            {products.map((item, index) => {
              return (
                <ProductItem
                  currentIndex={currentIndex}
                  index={index}
                  item={item}
                  key={String(item.id)}
                />
              );
            })}
          </div>
        </div>
        <CarouselControls
          onNext={onNext}
          onPrev={onPrev}
          currentIndex={currentIndex}
          itemCount={products.length - 1}
        />
      </Container>
    </section>
  );
};

const CarouselControls = ({ onNext, onPrev, currentIndex, itemCount }) => {
  return (
    <React.Fragment>
      {currentIndex > 0 ? (
        <div
          className="carousel-control-prev text-primary"
          data-slide="prev"
          onClick={onPrev}
        >
          <span className="sr-only">
            <i className={"h1 pe-7s-angle-left-circle"}></i>
          </span>
        </div>
      ) : null}
      {currentIndex !== itemCount ? (
        <div
          className="carousel-control-next text-primary"
          data-slide="next"
          onClick={onNext}
        >
          <span className="sr-only">
            <i className={"h1 pe-7s-angle-right-circle"}></i>
          </span>
        </div>
      ) : null}
    </React.Fragment>
  );
};

const ProductItem = ({ item, index, currentIndex }) => {
  return (
    <div className={`carousel-item ${index === currentIndex ? "active" : ""}`}>
      <Row className={"vertical-content"}>
        <Col lg={5}>
          <div className="features-box">
            <p className="h1 text-uppercase mb-3">{item.title}</p>
            <p className="web-desc">{item.description}</p>
            <ul className="list-unstyled mt-4 features-item-list">
              {item.benefits.map((benefit, key) => {
                return <li key={String(key)}>{benefit}</li>;
              })}
            </ul>
            <p className="font-weight-bold web-desc">
              Releasing {item.release}
            </p>
          </div>
        </Col>
        <Col lg={7}>
          <div className="features-img features-right text-right">
            <img
              src={`assets/images/${item.url}`}
              alt={item.title}
              className="img-fluid"
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Projects;
