import React from "react";
import { Container, Row } from "reactstrap";
import pricing from "../../constants/data/pricing.json";

//Import Section Title
import SectionTitle from "../common/section-title";

//Import Pricing
import PricingBox from "./pricing-box";

const Pricing = () => {
  return (
    <React.Fragment>
      <section className="section pt-5" id="pricing">
        <Container>
          {/* section title */}
          <SectionTitle
            title="Consultation Pricing"
            desc="We also provide consultation services in different categories to enhance your business and move forward or introduce you to the world of digitalization"
          />

          <Row className="mt-5">
            {/* pricing box */}
            <PricingBox prices={pricing} />
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Pricing;
