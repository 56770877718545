import React from "react";
import { Container, Row } from "reactstrap";
import testimonials from "../../constants/data/testimonials.json";

//Import Section Title
import SectionTitle from "../common/section-title";
import TestimonialsBox from "./TestimonialsBox";

const Testimonials = () => {
  return (
    <React.Fragment>
      <section className={"section"} id={"testi"}>
        <Container>
          {/* section title */}
          <SectionTitle
            title={"Testimonials"}
            desc="Our customers are our pride and below are some of the top feedback from our top customers"
          />

          <Row className="mt-5">
            <TestimonialsBox clients={testimonials} />
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Testimonials;
