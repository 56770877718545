import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";

const EntryPoint = () => {
  return (
    <React.Fragment>
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
    </React.Fragment>
  );
};

export default EntryPoint;
